import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectSections from "../../Data/Projects.json";
import images from "../../Assets/Projects/index";
import { ListGroup } from "react-bootstrap";
import Navbar from "../Navbar";
import { Card } from "react-bootstrap";
import Vimeo from '@u-wave/react-vimeo';

const STYLES = { videoIframeContainer: { padding: '100% 0 0 0', position: 'relative', }, videoIframe: { position: 'absolute', top: "15%", left: 0, width: '100%', height: "60%" } }

function Projects() {

  const [currentProject, setCurrentProject] = useState("");
  const [singleProject, showSingleProject] = useState(false);

  const showProject = (index) => {
    setCurrentProject(index);
    showSingleProject(true);
  };

  const hideSingleProject = () => {
    showSingleProject(false);
  };

  return (
    <>
    <Navbar />
    <Container fluid className="project-section">
      <p id="project-section-wip">This page is going through some changes. Please check back soon! <br></br>
      In the meantime, I want to tell you how in 2020 I had a series of dreams that I couldn't stop thinking about until I recreated them using a computer. Computers can do pretty cool things. <a href="https://www.elijahzulu.com/GalleryWEBGL/">First dream.</a> <a href="https://www.elijahzulu.com/avemaria/">Second dream.</a>
      </p>
      <Container>
      </Container>
    </Container>
    <Container fluid>
  </Container>    
    </>

  );
}

export default Projects;
